.container {
    padding: 1.5em 0 0 2em;
    margin-top: 0.5em;
    height: auto;
    min-height: 50vh;
    max-height: 90vh;
    overflow-y: auto;
}

@media(max-width: 800px){
    .layerPicker {
        display: none
    }

    .container{
        padding: 0;
        margin: 0;
        min-height: 0;
        width: 100%;
    }
}