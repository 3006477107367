.popupContainer {
    min-width: 15em;
}

.popupContainer div {
    max-height: 30vh;
    overflow-y: auto;
}

.popupContainer h2 {
    margin: 0 0 0.5em 0;
}

.popupContainer p {
    margin: 0 0 0 1em;
}