.container {
    color: var(--white);
    border: 2px outset var(--white);
    padding: 0.1em 0.5em;
    border-radius: 1em;
}

@media(max-width: 800px){
    .container{
        font-size: var(--very-small-font);
        border-width: 1px
    }
}