.container {
    padding: 1em;
    top: 5%;
    left: 2%;
    width: 350px;
    margin-bottom: 1em;
}

.container > div {
    margin-bottom: 0.5em
}

.checkBoxContainer {
    display: flex;
    justify-content: flex-end;
}

@media(max-width: 800px){
    .container {
        margin: 0;
        width: 100%;
        border-left: none;
        border-right: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .container > div {
        margin: 0
    }

    .checkBoxContainer {
        display: none
    }
}