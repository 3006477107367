.container {
    min-width: 1em;
    padding: 0;
    height: 10;
}

.container :global .mapboxgl-popup-content {
    padding: 2px 10px
}

.container h3 {
    margin: 0
}