.container {
    display: flex;
    align-items: center;
    color: var(--grey);
    padding: 0.5em 1em 0.5em 0.25em;
    text-transform: capitalize
}

.container:hover {
    background-color: var(--light-grey);
    cursor: pointer;
}

.container.selected, .container.selected:hover {
    background-color: var(--primary-light)
}

.container > svg {
    color: var(--grey);
    margin-right: 0.25em;
}