.container {
    display: inline-flex;
    flex-direction: column
}

.container svg {
    color: var(--dark);
    font-size: 30px;
}
.container svg:hover {
    color: var(--primary);
    cursor: pointer;
}
.top svg  {
    margin-top: 0;
}

.top , .bottom{
    height: 10px;
}

.hidden {
    visibility: hidden;
}