.container {
    bottom: 50px;
    right: 0;
    max-height: 50%;
    overflow-y: auto;

}

@media(max-width:800px){
    .container{
        position: fixed;
        width: 100%;
        height: calc(2*var(--horizontalBarHeight))
    }
}
.title {
    font-size: var(--big-font);
    padding: 0.5em;
}
.optionContainer span, .imgContainer span {
    font-size: var(--small-font)
}

.optionContainer, .optionLabel, .imgContainer {
    display: flex;
    align-items: center;
}

.optionContainer {
    padding: 0.25em 0.5em;
    justify-content: space-between
}

.imgContainer {
    padding: 0.25em 0.5em;
}

.imgContainer img {
    margin-right: 5px;
}

.optionContainer > img {
    width: 100%;
    min-width: 200px;
}

.symbolContainer {
    height: 20px;
    min-width: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px
}

.symbolContainer > svg {
    color: var(--grey);
}

.polygonSymbol {
    height: 100%;
    width: 100%;
}

.lineSymbol {
    height: 5px;
    width: 100%;
    background-color: var(--grey);
}

.sortSpacer {
    width: 34px;
    height: 30px;
}