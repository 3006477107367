.container{
    display: inline-flex;
    text-decoration: underline;
    cursor: pointer;
}

.container svg {
    color: var(--grey);
    margin-right: 0.25em;
}
