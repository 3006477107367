.container {

}

.heading {
    display: flex;
    align-items: center;
    font-size: var(--small-font);
    
}

.heading:hover {
    color: var(--grey)
}
.heading > svg {
    color: var(--grey)
}

.collapsable {
    margin-left: 1em;
}