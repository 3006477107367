.container {
    display:grid;
    grid-template-columns: var(--horizontalBarHeight) auto;
    grid-template-rows: var(--horizontalBarHeight) auto;
    height: 100vh;
}

.header {
    grid-column: 1 / span 2;
}

@media (max-width: 700px) {
    .container {
        height: 100vh;
        grid-template-columns: auto;
        grid-template-rows: var(--horizontalBarHeight) auto var(--horizontalBarHeight);

    }

    .header {
        grid-column: 1 / span 1;
    }

    .content {
        grid-row:2;
    }
} 

@media (max-width: 450px){
    .container{
     
        grid-template-rows: var(--horizontalBarHeight) auto calc(var(--horizontalBarHeight) + 56px);
    }
}

.container.sidebarHidden{
    display: flex;
    flex-direction: column;
}

.container.sidebarHidden > .content{
    flex: 1;
}