.container {
    padding: 1em;
    top: 25%;
    left: 2%;
    width: 350px;
    max-height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
    visibility: visible;
    -webkit-transition: max-height 0.5s; 
    transition: max-height 0.5s;

}

.layerPickerInnerContainer {
    display: flex;
    justify-content: space-between;
}
.layerPickerInnerContainer > div{
    margin-bottom: 0.25em
}

.description {
    display: grid;
    grid-template-columns: 30% 70%
}
.collapsed {
    max-height: 0;
    visibility: hidden;
}

.title {
    text-align: right;
    font-size: var(--big-font);
}

.layerActions {
    display: flex;
    justify-content: flex-end
}

@media(max-width: 800px){
    .container{
        width: 100%;
        display: block;
        max-height: none;
        border: none;
        border-radius: none;
        background: none;
    }
}