.container {
    height: 30px;
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    cursor: pointer;
}

.container > svg {
    margin-right: 0;
    font-size: 1.3em;
    color: var(--grey);
}

.container:hover {
    background:#f2743775;
}

.container:hover > svg {
    color: var(--primary)
}

.tooltip {
    background: var(--tooltip-yellow)!important;
    border: 1px solid var(--dark)!important;
    padding: 1px 10px!important;
    color: var(--dark)!important;
    text-transform: capitalize;
    border-radius: 0!important
}

.tooltip::after {
    display: none!important
}