.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
}

.searchField{
    margin-bottom: 1em
}

.searchField svg {
    color: var(--grey);
    margin-right: 0.25em;
}

.attribute{
    font-size: 0.7em;
}

.documentList{
    overflow-y: auto;
    background: var(--light-grey);
    padding: 0.5em;
    border: 1px inset;
    flex: 1;
    height: 100%
}


.documentTile{
    padding: 0.5em;
}
.documentInner {
    display: flex;
}
.documentTile:hover {
    border-style: inset
}
.documentTile img {
    height: 40px;
    margin-right: 10px;
}
.documentTitle {
    font-weight: 600
}
.documentActions {
    display: flex;
}

@media(max-width: 800px){
    .documentTile {
        font-size: var(--small-font);
        padding: 5px
    }

    .documentTile img {
        height: 30px
    }
}