.container {
    border: 1px solid var(--grey);
    padding: 0.5em 0;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--white);
    position: absolute;
    width: 100%;
}

.resultContainer {
    padding: 0.25em 0.5em
}

.resultSelected, .resultSelected:hover {
    background-color: var(--primary-light)
}


.resultUnselected:hover{
    background-color: var(--light-grey);
}

@media(max-width: 800px){
    .container{
        position: fixed;
        width: calc(100% - 2em);
        left: 0;
        margin-left: 1em;
    }
}

@media(max-height: 1000px){
    .container{
        max-height: 120px;
    }
}