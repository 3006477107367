.container {
    height: calc(100vh - var(--horizontalBarHeight));
}

.scaleBar{
    background-color: var(--transparent-widget)!important;
    bottom: 0!important;
    right: 269px!important;
    height: 28px;
    box-shadow: none!important;
    border-right: none!important;
}

@media(max-width: 800px){

    .scaleBar{
        position: fixed!important;
        bottom: 0!important;
        right: 0!important
    }

    .scaleBar.legendVisible {
        bottom: calc(3*var(--horizontalBarHeight))!important;
    }

    .scaleBar.navbarVisible {
        bottom: var(--horizontalBarHeight)!important;
    }
}