.container {
    padding: 7% 14%;
}

.linkContainer {
    display: flex;
    justify-content: flex-end;
}

.previewContainer {
    display: flex;
    justify-content: center;
    border: 1px solid var(--grey);
    height: 90%;
}

.previewContainer > img{
    width: 100%;
    height: auto;

}

.content {
    display: flex;
    flex-direction: column;
}

.buttonContainer {
    text-align: right;
    margin-top: 10px;
}