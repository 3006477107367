.container {
    padding: 0
}

.panel{
    float: right;
    width: 400px;
    margin-right: -400px;
    transition: margin 1s;
    display: flex;
    flex-direction: column;
}

.visible{
    margin-right: 0
}

.backLink{
    display: flex;
    text-decoration: underline;
    cursor: pointer;
}

.backLink svg {
    color: var(--grey);
    margin-right: 0.25em;
}