.container {
    text-align: center;
    color: var(--grey);
    flex: 1;
    cursor: pointer;
}

.tile{
    flex: 1;
    text-align: center;
    color: var(--grey);
}
.container:active, .container.selected {
    color: var(--primary-dark);
    flex: 1;
    text-align: center;
}

.container > svg {
    margin-bottom: -8px;
    color: var(--grey);
} 

.container:active > svg, .container.selected > svg{
    color: var(--primary-dark);
}

.container > div {
    font-size: var(--small-font);
    font-weight: 400
}