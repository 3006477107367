:root {
  --white: #fff;
  --black: #000;
  --dark: #2D2929;
  --primary: #F27437;
  --primary-light: #ffbc9b;
  --primary-dark: #f36623;
  --tooltip-yellow: #f8f6dd;
  --horizontalBarHeight: 50px;
  --grey: #7D7777;
  --light-grey:#e2e2e2;
  --translucent:  rgba(0, 0, 0, 0.47);
  --transparent-light: #0a0a0a52;
  --transparent-primary: rgba(242, 116, 55, 0.2);
  --transparent-widget: rgba(255,255,255,0.8);
  --very-small-font: 12px;
  --small-font: 0.85em;
  --big-font: 1.1em;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

*, ::after, ::before {
  box-sizing: inherit
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.raised {
  border: 3px outset;
  background-color: var(--transparent-widget);
  border-radius: 6px;
}

.mapWidget {
  z-index: 10;
  position: absolute;
  height: auto;
}
.button {
  padding: 0.3em 1em;
  border: 2px solid;
  display: inline-block;
  text-transform: capitalize;
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
  border-radius: 6px;
}
.button:hover {
  border-style: outset;
}
.button:active {
  border-style: inset;
  background-color: var(--primary-dark)
}

.buttonLight {
  background-color: var(--white);
  color: var(--primary)
}

.buttonLight:active {
  background-color: var(--primary-light)
}

.icon {
  color: var(--white)
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:var(--translucent);
  z-index: 90;
  padding: 5% 10%;
  height: 100%;
  width: 100%;
}

.modalContent{
  position: relative;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  padding: 20px;
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.title{
  font-size: 1.2em;
  margin-bottom: 0.5em;
  font-weight: 200;
}

.moreDescription{
  font-size: var(--small-font);
  padding: 0.5em;
  background: var(--light-grey);
  border-radius: 4px;
}

.mapboxgl-ctrl-attrib {
  height: 28px;
  display: flex;
  align-items: center;
  background-color: var(--transparent-widget);
  border-left: none;
}