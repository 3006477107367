.container {
    background: var(--light-grey);
    padding: 0.5em;
    border-radius: 4px;
}

.tagContainer {
    display: inline-flex;
    background-color: var(--primary);
    color: var(--white);
    padding: 4px;
    margin: 4px 4px 0 0;
    border-radius: 4px;
    align-items: center
}

.tagContainer > span > svg {
    width: 15px;
    height: 15px;
    margin-left: 0.25em;
}

.tagContainer > span > svg:hover {
    background: var(--transparent-light);
    border-radius: 10px;
}