.container {
    display: flex;
    align-items: center;
}

.container > span {
    font-size: var(--small-font)
}

.innerContainer {
    border: 2px solid var(--primary);
    border-radius: 6px;
    height: 20px;
    min-width: 20px;
    width: 20px;
    background-color: var(--primary-light);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
}

.innerContainer > svg {
    height: 15px;
    width: auto;
}

.innerContainer:hover {
    background-color: var(--transparent-primary)
}

.innerContainer.checked, .innerContainer.checked:hover {
    background: var(--primary);
    color: var(--white)
} 