.container{
    display: none;
    justify-content: space-around;
    border: 1px outset;
    box-shadow: -0px -1px 2px var(--light-grey);
    padding: 7px 5px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: var(--white);
    z-index: 100;
    height: var(--horizontalBarHeight);
} 

.modal{
    display: none;
    height: 100%;
    margin-top: 100vh;
    width: 100%;
    outline: none;
    transition: margin-top 1000ms;
    padding-bottom:60px;
    background: var(--white);
    overflow-y: auto;
}

.modal.slide {
    margin-top: 0;
}
.modalOverlay {
    display: none;
    padding: 55% 0% 0% 0%;
}

.shareModal {
    padding-left: 1em;
    padding-right: 1em;
    display: none
}

.documentsModal {
    padding: 1em 1em 3em 1em;
    display: flex;
}

@media(max-width: 800px){
    .container{
        display: flex
    }
    .modal{
        display: block
    }
    .modalOverlay{
        display: block
    }
    .shareModal, .documentsModal {
        display: flex
    }
}

@media(min-width: 600px){
    .modalOverlay {
        padding: 30% 20% 0% 20%;
   }
}
@media(max-height: 420px){
    .modalOverlay {
         padding: 10% 0% 0% 0%;
    }
}