.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    height: 300px;
    width: 700px;
    display: flex;
    flex-direction: column;
}

.urlContainer {
    display: flex;
    flex: 1;
    align-items: center;
}

.copyField {
    display: flex;
    width: 100%
}
.urlContainer input {
    flex: 1;
    padding: 0.5em;
    background: var(--light-grey);
    border: 1px inset var(--grey);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.buttonContainer {
    text-align: right
}

@media(max-width: 800px){
    .urlContainer button {
        color: var(--white);
        background: var(--primary);
        border: 1px solid var(--primary);
    }
}