.container > svg {
    width: 15px;
    height: 15px;
    margin-left: 0.25em;
}

.container.darkTheme > svg {
    color: var(--grey)
}

.container > svg:hover {
    background: var(--transparent-light);
    border-radius: 10px;
    color: var(--white)
}