.container {
    height: 100vh;
    background: var(--transparent-widget);
    width: 100vw;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 101;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner{
    height: 10vw;
    width: 10vw;
    border-radius: 10vw;
    border: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid var(--primary);
    animation: spin 0.5s linear infinite;
    -webkit-animation:spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg)}}; 