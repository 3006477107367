.container {
    background-color: var(--dark);
    height: var(--horizontalBarHeight);
    border-color: var(--dark);
    display: flex;
    justify-content: flex-end;
    border-radius: 0;
}


.innerContainer {
    flex: 1;
    align-items: center;
    display: flex;
    padding: 0 1em;
}

.innerContainer.right {
    justify-content: flex-end;
}

.externalLink {
    color: var(--white);
    text-decoration: underline;
    cursor: pointer;
    margin-left: 10px;
}

.logo {
    height: 50px;
}


@media(max-width: 800px){
    .externalLink{
        font-size: var(--very-small-font);
        width: 70px;
        margin-left: 5px;
    }
    .innerContainer{
        padding: 0
    }
    .logo{
        height: 30px;
    }
}

@media(max-width: 290px){
    .logo {
        height: 20px;
    }
}