.container {
    border: 1px solid var(--grey);
    padding: 0 0.5em;
    display: block;
}

.innerContainer {
    display: flex;
    align-items: center
}

.innerContainer > svg {
    color: var(--grey)
}

.innerContainer > input {
    border: none;
    padding-left: 0.5em;
    outline: none;
    flex: 1;
    background: none;
}